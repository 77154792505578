import React from "react";
import { useEffect } from "react";
import { navigate } from "gatsby";

// Just redirect to the home page.
const NotFoundPage: React.FC = () => {
  useEffect(() => {
    navigate("/archive");
  }, []);

  return null;
};

export default NotFoundPage;
